.footer-div {
  background-color: #000000;
  height: fit-content;
  min-height: 12vh;
  position: relative;
  width: 100%;
  padding: 10px;
  padding-top: 20px;
}

#footer-info-div {
  color: #e3ccae;
}

#footer-add {
  margin-left: 10px;
  border-right: #e3ccae 1px solid;
}

#footer-icon-div {
  display: flex;
  justify-content: flex-end;
}

.footer-icon {
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
}

#footer-company-logo {
  height: 50px;
}

#footer-copyright {
  color: #e3ccae;
  text-align: center;
  /* position: absolute;
  bottom: 5px;
  left: 0;
  right: 0; */
}

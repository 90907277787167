.contact-div {
  width: 95%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
  color: #eeeeee;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contact-icon {
  height: 60px;
  object-fit: contain;
  margin-bottom: 30px;
}

.contact-title {
  font-size: 40px;
  margin-top: 30px;
  font-family: "Milkalte";
  color: #eeeeee;
  font-weight: bolder;
}

.contact-input {
  margin: auto;
  margin-top: 20px;
  width: 95%;
  max-width: 500px;
}

.contact-textarea {
  margin: auto;
  margin-top: 20px;
  width: 95%;
  max-width: 500px;
}

#contact-send-button {
  margin-top: 20px;
  background-color: #ffd369;
  color: #393e46;
}

#contact-send-button:hover {
  box-shadow: rgba(238, 238, 238, 0.25) 0px 3px 8px;
}

#service-number-flex {
  margin-top: 20px;
}

.contact-text {
  font-size: 25px;
}

#whatsapp-btn {
  width: 35px;
  margin-left: 20px;
  cursor: pointer;
}

.contact-map {
  height: 500px;
  width: 95%;
  margin: auto;
  margin-top: 30px;
  max-width: 700px;
  border-radius: 15px;
}

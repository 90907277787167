#collabs-div {
  width: 95%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
  color: #eeeeee;
  padding-top: 30px;
  position: relative;
}

#collabs-icon {
  object-fit: contain;
  height: 60px;
}

#collabs-title {
  margin-top: 30px;
  font-size: 60px;
  font-family: "Milkalte";
  color: #eeeeee;
  font-weight: bolder;
}

#collabs-card-row {
  margin: auto;
  margin-top: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.collabs-card {
  width: fit-content;
  max-width: 300px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #222831;
  padding: 10px;
  margin: 10px;
  position: relative;
  box-shadow: rgba(5255, 211, 105, 0.25) 0px 2px 5px -1px,
    rgba(255, 211, 105, 0.3) 0px 1px 3px -1px;
}

.collabs-card-img {
  width: 280px;
  height: 150px;
}

.collabs-card-title {
  font-family: "Milkalte";
  font-size: 25px;
  font-weight: bold;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
}

.about-link-icon {
  height: 30px;
  cursor: pointer;
  margin: 5px;
}

.about-link-icon:hover {
  transform: scale(1.3);
}

#user-auth-div {
  padding-top: 20px;
  margin: auto;
  width: 95%;
  max-width: 1000px;
  text-align: center;
}

.user-auth-input {
  width: 100%;
  max-width: 300px;
  margin: auto;
  margin-bottom: 20px;
}

.user-auth-btn {
  background-color: #000000;
  border-color: #000000;
  color: #e3ccae;
  /* box-shadow: 0 2px 4px 0 #e3ccae, 0 3px 10px 0 #e3ccae; */
  margin-bottom: 20px;
}

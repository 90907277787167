#home-div {
  width: 95%;
  max-width: 1280px;
  text-align: center;
  margin: auto;
}

.home-hero {
  position: relative;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/videos%2FDME%20New%20Logo%20Video%20(1).gif?alt=media&token=c80b7e88-7e16-4216-9311-650b805d17e8");
  background-size: cover;
  background-position: center;
}

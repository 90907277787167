@font-face {
  font-family: "Milkalte";
  src: url(./fonts/Milkalte-4BmnK.otf);
}

.app-div {
  overflow-x: hidden;
}

#app-body-div {
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: #393e46;
  min-height: 77vh;
}

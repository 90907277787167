.header-div {
  background-color: #222831;
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-height: 11vh;
  height: fit-content;
  position: sticky;
  top: 0;
  z-index: 999;
}

.company-logo {
  height: 75px;
}

#header-dropdown {
  margin-right: 10px;
  color: #eeeeee;
  /* border-top: #eeeeee solid 2px; */
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

#header-dropdown:hover {
  padding-top: 5px;
  padding-bottom: 15px;
}

#header-dropdown.active {
  color: #ffd369 !important;
  border-top: #ffd369 solid 2px;
}

.header-link {
  margin-right: 10px;
  color: #eeeeee !important;
  /* border-top: #eeeeee solid 2px; */
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-link:hover {
  padding-top: 5px;
  padding-bottom: 15px;
}

.header-link.active {
  color: #ffd369 !important;
  border-top: #ffd369 solid 2px;
}

#user-bar-div {
  position: absolute;
  right: 10px;
  color: #eeeeee;
}

#logout-btn {
  margin-left: 10px;
  font-size: 12px;
}

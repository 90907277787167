#add-recording-div {
  padding: 10px;
  width: 95%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
  position: relative;
}

#add-recording-back-btn {
  position: absolute;
  height: 30px;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

#add-recording-title {
  margin-top: 30px;
  font-size: 40px;
  font-family: "Milkalte";
  color: #eeeeee;
  font-weight: bolder;
}

#add-recording-src-btn {
  width: fit-content;
  margin: auto;
  margin-top: 30px;
  background-color: #ffd369;
  color: #222831;
  border: none;
}

#add-recording-input-div {
  margin-top: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

#add-recording-input {
  width: 85%;
  min-width: 800px;
  margin-top: 10px;
}

#collabs-upload-btn {
  margin-top: 20px;
  background-color: #ffd369;
  color: #222831;
}

#add-recording-btn {
  width: fit-content;
  margin: auto;
  margin-top: 30px;
  background-color: #222831;
  color: #eeeeee;
  border: none;
}

#about-div {
  width: 95%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
  color: #eeeeee;
  padding-top: 30px;
  padding-bottom: 30px;
}

#about-company-logo {
  height: 100px;
  object-fit: contain;
}

#about-title {
  font-size: 60px;
  font-family: "Milkalte";
  margin-top: 30px;
}

#about-subtitle {
  color: #ffd369;
  font-size: 20px;
  margin-top: 30px;
}

.about-text {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  font-size: 18px;
  font-family: "Lucida Console", "Courier New", monospace;
}

/* #about-profile-row {
  margin-top: 50px;
  padding: none;
  box-shadow: 0 2px 4px 0 #eeeeee, 0 3px 10px 0 #ffd369;
  border-radius: 15px;
  background-color: rgb(0, 0, 0, 0.25);
} */

#about-profile-row {
  margin-top: 50px;
}

#about-founder-img-col {
  margin: none;
}

#desmond-pic {
  width: auto;
  border-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 2px;
}

#about-info-col {
  margin: 0%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.25);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

#about-info-row {
  text-align: center;
  margin: 0%;
}

#about-founder-name {
  font-size: 40px;
  font-family: "Milkalte";
  font-weight: bold;
  margin: auto;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: #ffd369 2px solid;
  width: fit-content;
}

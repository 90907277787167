#service-div {
  width: 95%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
  color: #eeeeee;
  padding-top: 30px;
  position: relative;
}

#service-icon {
  height: 60px;
  object-fit: contain;
}

#service-title {
  margin-top: 30px;
  font-size: 60px;
  font-family: "Milkalte";
  color: #eeeeee;
  font-weight: bolder;
}

#service-subtitle {
  color: #ffd369;
  font-size: 20px;
  margin-top: 30px;
  font-family: "Lucida Console", "Courier New", monospace;
}

#service-intro-row {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  font-size: 18px;
  font-family: "Lucida Console", "Courier New", monospace;
}
/* 
#service-intro-row {
  margin-top: 30px;
  min-height: 30vh;
  padding: 20px;
  box-shadow: 0 2px 4px 0 #eeeeee, 0 3px 10px 0 #ffd369;
  border-radius: 10px;
} */

#service-carousel-row {
  margin-top: 50px;
}

#service-carousel {
  height: 500px;
}

.service-carousel-img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
}

/* #service-carousel-1 {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/images%2Fservice%2FCarousel1.jpg?alt=media&token=c6667c89-fb98-496b-bf99-c2a736f9e886);
  background-size: cover;
  background-position: center;
} */

#service-carousel-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

#service-trash-btn-row {
  width: 100%;
  margin-top: 30px;
  display: inline-flex;
  justify-content: flex-end;
}

.service-admin-btn {
  height: 30px;
  cursor: pointer;
  object-fit: contain;
}

#service-trash-done-btn {
  width: fit-content;
  background-color: #eeeeee;
  color: #222831;
  font-weight: bold;
  height: 30px;
  font-size: 12px;
}

#service-video-card-row {
  margin: auto;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

.service-videos-card {
  width: fit-content;
  max-width: 330px;
  background-color: transparent;
  border: none;
  position: relative;
}

.service-delete-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 30px;
  cursor: pointer;
}
